<template>
  <div class="py-4 px-4">
    <div class="card">
      <div class="card-header">
        <div class="col pl-0">
          <div v-if="tableData.length" class="input-group input-group-flush d-flex flex-row-reverse">
            <input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" @keyup.enter.prevent="fetchUsers(true)" />
            <span class="input-group-text border-0">
              <i class="fe fe-search"></i>
            </span>
          </div>
        </div>

        <div class="col-auto d-flex align-items-end px-0">
          <template>
            <button class="btn btn-sm btn-primary mr-2" @click="addPassengerModal">
              Add Passenger 
            </button>
          </template>
          <template v-if="tableData.length">
            <button v-if="trip?.route?.is_exclusive" class="btn btn-sm btn-outline-primary mr-2" @click="openNotificationModal">
              Notify users
            </button>
            <download-button label="Download Report" @download="downloadReport()">
            </download-button>
          </template>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col md:flex-row gap-4 justify-between w-full items-center mb-4" v-if='tableData.length'>
        <form class='flex items-start gap-8 mb-4'>
          <div class="flex flex-col gap-3">
            <div class="flex items-center">
              <input type="checkbox" id='all' v-model="selected_all" @change="handleAllUsersSelection($event)" class='ml-2' />
              <label for="all" class='mb-0 ml-2'>Select all passengers</label>
            </div>
            <button type="button" class="btn border border-dark text-dark outline-none text-xs font-medium !p-2 w-fit disabled:bg-slate-500"
              v-if="(selectedUsers.length !== 0 || selected_all !== false)" @click="toggleMultipleNotifyModal"
            >
              Notify Selected
            </button>
          </div>

          <div class="flex">
            <div @click="seePickups">
              <input type="radio" name="fav_language" :checked="manifestType === 'pickup'" />
              <label for="html" class='!m-0'>Pick ups</label><br />
            </div>
            &nbsp; &nbsp;
            <div @click="seeDropoffs">
              <input type="radio" name="fav_language" value="CSS" :checked="manifestType === 'dropoff'" />
              <label for="css" class='!m-0'>Drop offs</label><br />
            </div>
          </div>
        </form>

        <div class="flex items-center gap-4">
          <button class="btn border border-dark text-dark outline-none text-xs font-medium !p-2 disabled:bg-slate-500" v-if="userHasPermission('bookings001') && (selectedUsers.length !== 0 || selected_all !== false)" @click="isTransferModal = true">
            Transfer booking
          </button>
        </div>
      </div>

      <div v-if="!loading">
        <div v-if="!isEmpty">
          <b-card class="" v-for="(users, bus_stop, index) in filteredData" :key="index">
            <template #header>
              <h6 class="mb-0">
                <b-container class="bv-example-row" fluid>
                  <b-row align-h="between" align-v="center">
                    <b-col>
                      <div class="flex-flex-row align-top">
                        <img class="icon" src="@/assets/svg/green-location.svg" />
                        <div>
                          <p class="text-lg" style="
                              font-size: 1rem;
                              margin-bottom: 2px;
                              margin-top: 5px;
                            ">
                            {{ bus_stop }}
                          </p>
                          <p style="margin-bottom: 0">
                            {{ users.length }} passenger(s)
                          </p>
                        </div>
                      </div>
                    </b-col>
                    <b-col class="text-right">
                      <b-button variant="primary" @click="toggleNotifyModal(bus_stop, users)" size="sm">Notify Bus-stop</b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </h6>
            </template>
            <div class="text-dark">
              <div v-for="passenger in users" :key="passenger?.id">
                <div class='flex items-center mb-4'>
                  <input v-if='shouldShowTransfer' type="checkbox" name="checked_all" :checked="selectedUsers.find(
                    (user) => user.id === passenger?.id
                  ) || selected_all
                    " @input="() => {selectUser(passenger); selected_all = false}">
                  <b-col>
                    <div class="flex gap-3">
                      <div class='flex'>
                        <user-avatar :user="passenger?.user" size="sm" :show-badge="passenger?.is_first_booking" badge-text="New"></user-avatar>
                      </div>
                      <div>
                        <router-link class="sh-page-link" :to="{
                          name: 'ShowUser',
                          params: { userId: passenger?.user?.id },
                        }">
                          <p class="mb-0">
                            {{
                              `${passenger?.user?.fname} ${passenger?.user?.lname}`
                            }}
                          </p>
                        </router-link>
                        <span>{{ passenger?.user?.email }}</span> <br />
                        <span v-if="passenger?.user?.bus_captain_id">
                          <svg-template code="captain" />
                          <span class="captain-text ml-1">Bus Captain</span>
                        </span>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="text-center">
                    <route-description :pickup="passenger?.pickup?.location" :destination="passenger?.destination?.location">
                    </route-description>
                  </b-col>
                  <b-col class="text-center">{{
                    passenger?.user?.phone
                  }}</b-col>
                  <b-col>{{
                    formatBookingTime(passenger?.created_at)
                  }}</b-col>
                  <b-col class="text-center">
                    <span class="sh-badge" :class="passenger?.check_in_meta?.dropOffClass">
                      {{ passenger?.check_in_status }}
                    </span>
                  </b-col>
                  <b-col class="text-center"><img @click="toggleUserNotifyModal(passenger)" style="cursor: pointer" class="icon" src="@/assets/svg/blue-notification.svg" /></b-col>
                </div>
              </div>
            </div>
          </b-card>
        </div>

        <b-card v-else>
          <div class="d-flex justify-content-between align-items-center flex-column py-3">
            <img class="mb-2" src="@/assets/img/searchEmptyState.svg" alt="search" />
            <h3 class="pb-1 mb-1" style="font-weight: bolder">
              No bus stop or user found
            </h3>
            <p class="pb-0 mb-0 text-muted">Try adjusting your search</p>
            <p class="pt-0 mt-0 text-muted">to find what you are looking for</p>
          </div>
        </b-card>
      </div>

      <div v-else>
        <div class="text-center text-secondary my-2">
          <strong>Loading...</strong>
        </div>
      </div>
    </div>
    <b-modal :id="notificationModalId" title="Notify Users (In App Push Notifications)" @cancel="closeNotificationModal" @ok="notifyUsers" no-close-on-backdrop no-close-on-esc>
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="notificationErrorMessage">
          {{ notificationErrorMessage }}
        </div>
        <form>
          <div class="form-group">
            <label for="notification-title">Message Title</label>
            <input v-model="notification.title" required type="text" class="form-control" placeholder="Notification title" id="notification-title" />
          </div>
          <div class="form-group">
            <label for="notification-description">Message Description</label>
            <textarea v-model="notification.description" class="form-control form-control-auto notification-text-area" data-autosize="" rows="3" placeholder="Start a notification..." id="notification-description" required></textarea>
          </div>
          <p class="font-width-normal mb-0">SMS Notification</p>
          <p class="text-muted font-weight-light mt-0">
            All users will receive SMS notifications
          </p>
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="form-group">
                <div class="form-check">
                  <input v-model="notification.isSms" class="form-check-input" type="checkbox" id="is-sms-notification" />
                  <label class="form-check-label" for="is-sms-notification">SMS Notifications</label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button :disabled="sendingNotification || !isValidNotificationForm" variant="primary" class="float-right" @click="notifyUsers">
            {{ sendingNotification ? 'Processing...' : 'Notify Users' }}
          </b-button>
          <b-button :disabled="sendingNotification" variant="secondary" class="float-right mr-2" @click="closeNotificationModal()">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal :id="addPassengerModalId" title="Add passenger to trip" @cancel="closeNotificationModal" size="xl" @ok="refreshTable" no-close-on-backdrop no-close-on-esc :hide-footer="true">
      <UserPicker @selected="addSelectedPassengers" @cancel="dismissPassengerModal" />
    </b-modal>

    <b-modal :id="addPassengerToTripModalId" title="Add passengers to trip" @cancel="closeNotificationModal" size="xl" @ok="refreshTable" no-close-on-backdrop no-close-on-esc :hide-footer="true">
      <AddPassengers :routeBusStops="busStops" :selectedUsers="selectedPassengers" @cancel="dismissPassengerModal" @proceed="proceedWithSelectedPassengers" />
    </b-modal>

    <BusstopNotificationModal :id="busstopModalId" @close="hideNotifyUserModal" :busstop="selected_busstop" :passengers="busstop_passengers" @successfullyNotified="notifucationSuccess" />

    <UserNotificationModal :id="userNotificationModalId" @close="hideUserNotifyModal" :passenger="busstop_passenger" @userSuccessfullyNotified="userNotifucationSuccess" />

    <BS_modal :show='isTransferModal' title='Transfer booking' @close='isTransferModal = false' modalId='isTransferModal' size="lg">
      <span class='text-[#667085] text-sm mb-5' v-if='form.route'>
        Transfer bookings to {{ routes.find((r) => r.id === form.route).route_code }} ( {{ routes.find((r) => r.id === form.route).pickup }} to {{ routes.find((r) => r.id === form.route).destination }} ) <span v-if='form.itinerary'> {{ itineraries.find((i) => i.id === form.itinerary).trip_time
        }}</span>
      </span>

      <br> <br>

      <div class="form mb-3" v-if="formStep === 0">
        <div class="form-group mb-3">
          <label class="form-label">Select Route</label>
          <v-select v-model="form.route" class="form-group" placeholder="Search" :reduce="route => route.id" :options="routes" label="route_code" required>
            <template v-slot:option="route">
              <span>{{ `${route.route_code ? route.route_code + ' - ' : ''} From ${route.pickup} to ${route.destination}` }}</span>
            </template>
            <template v-slot:selected-option="route">
              <span>{{ `${route.route_code ? route.route_code + ' - ' : ''} From ${route.pickup} to ${route.destination}` }}</span>
            </template>
          </v-select>
        </div>

        <div class="form-group mb-3" v-if="!fetchingItineraries && itineraries.length">
          <label class="form-label">Assign Itinerary</label>
          <select class="form-control" v-model="form.itinerary">
            <option :value="null">-Select-</option>
            <option v-for="(option, index) in itineraries" :value="option.id" :key="index">{{ option.trip_time }}</option>
          </select>
        </div>

        <div v-else class="form-group">
          <div class="d-flex align-items-center" v-if="fetchingItineraries">
            <div class="spinner-border spinner" role="status"></div>
            <p class="text-center m-0 loading-text"><span>Loading route itineraries...</span></p>
          </div>
          <p v-else-if="form.route">
            No itineraries available for selected route.
          </p>
          <p v-else>Please select a route</p>
        </div>

        <template v-if="form.itinerary">
          <template v-if="itineraryVehicles.length && !loadingItineraryVehicles">
            <div class="form-group">
              <label class="form-label">Select Vehicle</label>
              <v-select v-model="form.vehicle" class="form-group" :options="itineraryVehicles" label="name" :filterBy="vehicleFilter" required placeholder="Select a vehicle to assign">
                <template v-slot:option="option">
                  <p class="mb-2">
                    {{ option.vehicle.brand }} •
                    {{ option.vehicle.name }} {{ option.vehicle.registration_number }} •
                    {{ option.vehicle.seats }} Seater
                  </p>
                  <p class="mb-0">Driver: {{ option.driver.fname }} {{ option.driver.lname }}</p>
                </template>
                <template v-slot:selected-option="option">
                  <p class="mb-0">
                    {{ option.vehicle.brand }} •
                    {{ option.vehicle.name }} {{ option.vehicle.registration_number }} •
                    {{ option.vehicle.seats }} Seater
                  </p>
                  <p class="mb-0 pl-3">Driver: {{ option.driver.fname }} {{ option.driver.lname }}</p>
                </template>
              </v-select>
            </div>
          </template>

          <template v-else>
            <div class="d-flex align-items-center" v-if="loadingItineraryVehicles">
              <div class="spinner-border spinner" role="status"></div>
              <p class="text-center m-0 loading-text"><span>Loading itinerary vehicles...</span></p>
            </div>
            <p v-else-if="form.itinerary">
              No vehicles attached to selected route itinerary.
            </p>
          </template>
        </template>

        <label class="flex gap-3 my-4" for='only'> <input type="checkbox" name="only" id="only" v-model='onlyToday'> Transfer booking for only today</label>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group" v-if='!onlyToday'>
            <label class="form-label">Start Date</label> 
            <v-datepicker style="width: 100%" v-model="startDateRange" placeholder="Select date" class='form-group' :disabled-date="(date) => date < new Date().setHours(0, 0, 0, 0)">
            </v-datepicker>

          </div>
          <div class="form-group" v-if='!onlyToday'>
            <label class="form-label">End Date</label>
            <v-datepicker style="width: 100%" v-model="dateRange" placeholder="Select date" class='form-group' :disabled-date="(date) => date < new Date(this.startDateRange) ">
            </v-datepicker>
          </div>
        </div>

      </div>

      <div class="form mb-3" v-if="formStep === 1">
        <b-table striped hover selectable show-empty responsive :items="selectedUsers" :fields="transferFields">
          <template #cell(name)="data">
            {{ data.item.user.fullname }}
          </template>
          <template #cell(old_pickup)="data">
            {{ data.item.pickup.location }}
          </template>
          <template #cell(old_dropoff)="data">
            {{ data.item.destination.location }}
          </template>
          <template #cell(new_pickup)="data">
            <select v-model="data.item.new_pickup" class="form-select form-control" placeholder="Search" :options="TransferBusStops.filter((i) => i.is_pickup === 1)" label="id" required>
              <option v-for='busstops in TransferBusStops.filter((i) => i.is_pickup === 1)' :value='busstops'>{{ busstops.name }}</option>
            </select>
          </template>
          <template #cell(new_dropoff)="data">
            <select v-model="data.item.new_dropoff" class="form-select form-control" placeholder="Search" :options="TransferBusStops.filter((i) => i.is_dropoff === 1)" label="id" required>
              <!-- <template v-slot:option="busstops">
                <span>{{ busstops.name }}</span>
              </template>
              <template v-slot:selected-option="busstops">
                <span>{{ busstops.name }}</span>
              </template> -->
              <option v-for='busstops in TransferBusStops.filter((i) => i.is_dropoff === 1)' :value='busstops'>{{ busstops.name }}</option>
            </select>
          </template>
        </b-table>
      </div>

      <section>
        <div class="w-100" v-if="formStep === 0">
          <b-button :disabled="processing || !isValidForm" variant="primary" class="float-right" @click="formStep++">
            Continue
          </b-button>
          <b-button :disabled="processing" variant="secondary" class="float-right mr-2" @click="isTransferModal = false">
            Cancel
          </b-button>
        </div>
        <div class="w-100" v-if="formStep === 1">
          <b-button :disabled="isTransferloading || !isValidForm" variant="primary" class="float-right" @click='transferPassengers'>
            {{ isTransferloading ? 'Processing...' : 'Transfer' }}
          </b-button>
          <b-button :disabled="isTransferloading" variant="secondary" class="float-right mr-2" @click="formStep--">
            back
          </b-button>
        </div>
      </section>
    </BS_modal>

    <MultipleBusstopNotificationModal id="multipleNotifyUsersModalId" @close="hideMultipleNotifyUserModal" :passengers="busstop_passengers" @successfullyNotified="multipleNotificationSuccess" />
  </div>
</template>

<script setup>
import BS_modal from '@/components/core/modals/BS_modal.vue'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import SvgTemplate from '@/components/core/svg-template'
import DownloadButton from '@/components/core/DownloadButton'
import UserAvatar from '@/components/core/UserAvatar.vue'
import UserPicker from '@/components/core/UserPicker.vue'
import AddPassengers from '@/components/modules/commute/trips/AddPassengers.vue'
import BusstopNotificationModal from '@/views/Trips/components/BusstopNotificationModal'
import UserNotificationModal from '@/views/Trips/components/UserNotificationModal'
import { DoNotShowOnProd } from '@/composables/core/permissions'
import MultipleBusstopNotificationModal from '@/views/Trips/components/MultipleBusstopNotificationModal.vue'
import { userHasPermission } from '@/composables/core/permissions'

const transferFields = [
  {
    key: 'name',
    label: 'Passenger Name'
  },
  {
    key: 'old_pickup',
    label: 'Old pickup'
  },
  {
    key: 'new_pickup',
    label: 'New pickup'
  },
  {
    key: 'old_dropoff',
    label: 'Old Dropoff'
  },
  {
    key: 'new_dropoff',
    label: 'New Dropoff'
  },
]
const fields = [
  {
    key: 'name',
    label: 'Passenger'
  },
  {
    key: 'route',
    label: 'Route'
  },
  {
    key: 'route',
    label: 'Route'
  },
  {
    key: 'phone',
    label: 'Phone'
  },
  {
    key: 'created_at',
    label: 'Booked On'
  },
  {
    key: 'pickup_status',
    label: 'Pickup Status'
  },
  {
    key: 'drop_off_status',
    label: 'Drop Off Status'
  }
]
const waitlistFields = [
  {
    key: 'route_code',
    label: 'Route'
  },
  {
    key: 'itenery',
    label: 'Time'
  },
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'phone',
    label: 'Phone Number'
  },
  {
    key: 'email',
    label: 'Email Address'
  },
  {
    key: 'busstop',
    label: 'Busstop'
  }
]

</script>

<script>
import TripDetail from './trip-detail-mixin'
import { format, parseISO } from 'date-fns'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import { extractErrorMessage } from '@/utils/helpers'
import Manifest from '@/utils/Manifest.js'
import 'core-js/actual/array/group-by'
import routeResource from '@/api/route'


export default {
  name: 'TripPassengers',
  mixins: [TripDetail],
  data()
  {
    return {
      onlyToday: true,
      formStep: 0,
      itineraries: [],
      startDateRange: [],
      dateRange: [],
      processing: false,
      fetchingItineraries: false,
      form: {
        route: null,
        itinerary: null,
        vehicle: null
      },
      loadingItineraryVehicles: false,
      itineraryVehicles: [],
      routes: [],
      isDestinationRouteLoading: false,
      isTransferloading: false,
      transfer_vehicle: '',
      transfer_itinerary: '',
      destination_route: '',
      isTransferModal: false,
      manifestType: 'pickup',
      selectedPassengers: [],
      selectedUsers: [],
      selected_all: false,
      showNotifyModal: false,
      busstopModalId: 'busstopNotificationModal',
      userNotificationModalId: 'userNotificationMod\al',
      busStops: [],
      TransferBusStops: [],
      search: '',
      waitlistTableData: [],
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      tableData: [],
      manifestToShow: {},
      dropOffManifest: {},

      busCaptain: null,
      notificationErrorMessage: null,
      sendingNotification: false,
      notification: {
        title: '',
        description: '',
        isSms: false
      },
      notificationModalId: 'passengers-manifest-notification-modal',
      addPassengerModalId: 'add-passenger-modal',
      addPassengerToTripModalId: 'add-passenger-to-trip-modal',
      selected_busstop: '',
      busstop_passengers: [],
      busstop_passenger: {},
      newObj: {}
    }
  },
  computed: {
    isValidForm()
    {
      return this.form.route && this.form.itinerary && this.form.vehicle
    },
    isEmpty()
    {
      return Object.keys(JSON.parse(JSON.stringify(this.filteredData)))
        .length === 0
    },
    filteredData()
    {
      if (!this.search) {
        return this.manifestToShow
      } else {
        const newObj = {}
        for (const busStop in this.manifestToShow) {
          const busStopPassenger = this.manifestToShow[busStop]
          for (let index = 0; index < busStopPassenger.length; index++) {
            const passenger = busStopPassenger[index]
            const firstName = passenger.user.fname.toLowerCase()
            const lastName = passenger.user.lname.toLowerCase()
            const email = passenger.user.email.toLowerCase()
            const fullName = firstName + ' ' + lastName
            if (
              firstName.includes(this.search.toLowerCase()) |
              lastName.includes(this.search.toLowerCase()) |
              email.includes(this.search.toLowerCase()) |
              fullName.includes(this.search.toLowerCase()) |
              busStop.toLowerCase().includes(this.search.toLowerCase())
            ) {
              newObj[busStop] = newObj[busStop] ?? []
              newObj[busStop].push(passenger)
            }
          }
        }
        return newObj
        // return this.manifestToShow
      }
    },
    shouldShowTransfer(){
      // const TripDate = this.manifestToShow[Object.keys(this.manifestToShow)[0]][0].created_at.split(' ')[0]
      // const today = moment().format('YYYY-MM-DD')
      // return TripDate === today
      return true
    },
    tablePageText()
    {
      return this.totalRecords
        ? `Showing ${this.totalRecords} record${this.totalRecords > 1 ? 's' : ''
        }`
        : ''
    },
    isValidNotificationForm()
    {
      return this.notification?.title && this.notification?.description
    },
    single_user_busstop()
    {
      return this.busstop_passenger?.pickup?.location.includes('bus stop') ||
        this.busstop_passenger?.pickup?.location.includes('Bus stop')
        ? this.busstop_passenger?.pickup?.location
        : this.busstop_passenger?.pickup?.location + ' ' + 'bus stop'
    },

    group_user_busstop()
    {
      return this.selected_busstop.includes('bus stop') ||
        this.selected_busstop.includes('Bus stop')
        ? this.selected_busstop
        : this.selected_busstop + ' ' + 'bus stop'
    }
  },
  created()
  {
    this.fetchBookings()
    this.fetchWaitlist()
    this.fetchRoutes()
  },

  methods: {
    transferPassengers()
    {
      const schedule_ids = this.selectedUsers.map((user) =>
      {
        if (!user.new_pickup || !user.new_dropoff) {
          this.$toastr.e('Please select a new pickup and dropoff for all passengers')
          // break the loop and function
          throw new Error('Please select a new pickup and dropoff for all passengers')
        }
        return {
          pickup_id: user.new_pickup.id,
          dropoff_id: user.new_dropoff.id,
          user_id: user.user_id,
        }
      })

      const sent_data = {
        schedule_bus_stops: schedule_ids,
        source_itinerary_id: this.trip.route_itinerary_id,
        destination_itinerary_id: this.form.itinerary,
        // start_date: this.onlyToday ? moment().format('YYYY-MM-DD') : moment(this.startDateRange).format('YYYY-MM-DD'),
        // end_date: this.onlyToday ? moment().format('YYYY-MM-DD') : moment(this.dateRange).format('YYYY-MM-DD'),
        start_date: this.onlyToday ? this.trip?.trip_date : moment(this.startDateRange).format('YYYY-MM-DD'),
        end_date: this.onlyToday ? this.trip?.trip_date : moment(this.dateRange).format('YYYY-MM-DD'),
      }
      this.isTransferloading = true
      this.axios
        .post('/v1/user-route-schedules/transfers', sent_data)
        .then((res) =>
        {
          this.$toastr.s('Passengers transferred successfully')
          this.fetchBookings()
          this.fetchWaitlist()
          this.isTransferModal = false
          this.selectedUsers = []
          this.form = {
            route: null,
            itinerary: null,
            vehicle: null
          }
          this.formStep = 0

        })
        .catch((e) =>
        {
          this.$toastr.e(
            extractErrorMessage(e, e.message || 'Failed to transfer passengers')
          )
        })
        .finally(() => (this.isTransferloading = false))
    },
    fetchRoutes()
    {
      routeResource.listRoute({
        params: { status: 1, limit: 1000000 }
      }).then((res) =>
      {
        this.routes = res
      })
    },
    fetchItineraryDrivers()
    {
      this.loadingItineraryVehicles = true
      this.axios(`/v1/route-itineraries/${this.form.itinerary}/vehicles`).then((res) =>
      {
        this.itineraryVehicles = (res.data.data || []).map((obj) =>
        {
          return { ...obj, name: obj.vehicle?.name }
        })
      }).catch((e) =>
      {
        this.$toastr.e(extractErrorMessage(e, e.message || 'Failed to fetch data'))
        this.close()
      }).finally(() => this.loadingItineraryVehicles = false)
    },
    fetchRouteItineraries()
    {
      this.itineraries = []
      this.fetchingItineraries = true
      this.axios.get(`/v1/routes/${this.form.route}/itineraries?itinerary_only=1`)
        .then((res) =>
        {
          this.itineraries = res.data.data
        })
        .catch((err) =>
        {
          this.$toastr.e('An error occurred.')
        })
        .finally(() => this.fetchingItineraries = false)
    },
    vehicleFilter(option, label, search)
    {
      return filterVehicleBy(option?.vehicle, label, search)
    },
    handleAllUsersSelection(e)
    {
      this.$nextTick(() =>
      {
        if (e.target.checked) {
          const users = []
          Object.keys(this.filteredData).forEach((busStop) =>
          {
            this.filteredData[busStop].forEach((passenger) =>
            {
              users.push(passenger)
            })
          })
          this.selectedUsers = users
          this.selected_all = true
        } else {
          this.selectedUsers = []
          this.selected_all = false
        }
      })
    },
    selectUser(selectedUser)
    {
      const index = this.selectedUsers.findIndex(
        (user) => user.id === selectedUser.id
      )
      if (index !== -1) {
        this.selectedUsers.splice(index, 1)
      } else {
        selectedUser.new_pickup = null
        selectedUser.new_dropoff = null
        this.selectedUsers.push(selectedUser)
      }
    },
    notifucationSuccess()
    {
      this.$swal({
        icon: 'success',
        title: 'Notification sent',
        text: `You have successfully sent a notification to users in ${this.group_user_busstop}`,
        showCloseButton: true
      })
    },
    multipleNotificationSuccess()
    {
      this.selectedUsers = []
      this.selected_all = false
      this.$swal({
        icon: 'success',
        title: 'Notification sent',
        text: 'You have successfully sent a notification to users selected',
        showCloseButton: true
      })
    },

    userNotifucationSuccess()
    {
      this.$swal({
        icon: 'success',
        title: 'Notification sent',
        text: `You have successfully sent a notification to ${this.busstop_passenger?.passenger_json?.fullname} in ${this.single_user_busstop}`,
        showCloseButton: true
      })
    },
    toggleNotifyModal(busStop, users)
    {
      this.selected_busstop = busStop
      this.busstop_passengers = users
      this.$bvModal.show(this.busstopModalId)
    },
    hideNotifyUserModal()
    {
      this.$bvModal.hide(this.busstopModalId)
    },
    toggleMultipleNotifyModal()
    {
      this.busstop_passengers = this.selectedUsers
      this.$bvModal.show('multipleNotifyUsersModalId')
    },
    hideMultipleNotifyUserModal()
    {
      this.$bvModal.hide('multipleNotifyUsersModalId')
    },

    toggleUserNotifyModal(user)
    {
      this.busstop_passenger = user
      this.$bvModal.show(this.userNotificationModalId)
    },
    hideUserNotifyModal()
    {
      this.$bvModal.hide(this.userNotificationModalId)
    },
    showComingSoon()
    {
      this.$toastr.s('coming soon')
    },
    seePickups()
    {
      this.manifestType = 'pickup'
    },
    seeDropoffs()
    {
      this.manifestType = 'dropoff'
    },
    getPickupManifest()
    {
      const manifest = new Manifest()
    },
    refreshTable()
    {
      this.fetchBookings()
    },
    /**
     * @returns {Map}
     * */
    async fetchRouteCaptain()
    {
      try {
        const routeId = this.$attrs.routeId || this.trip.route_id
        const params = {
          related: ''
        }
        const captains = await this.axios
          .get(`/v1/routes/${routeId}/bus-captains`, { params })
          .then((res) => res.data.data)
        return captains.reduce((res, item) =>
        {
          const key = `${item.route_itinerary_id}_${item.user_id}`
          res.set(key, item)
          return res
        }, new Map())
      } catch (e) {

        return new Map()
      }
    },
    async fetchWaitlist()
    {
      try {
        const params = {
          booking_date: [format(parseISO(this.trip.created_at), 'yyyy-MM-dd')],
          itinerary_id: this.trip.route.itineraries[0].id,
          route_code: this.trip.route_id
        }
        await this.axios
          .get(
            `/waitlist/itenery/${params.route_code}/${params.booking_date}?itenery=${params.itinerary_id}`
          )
          .then((res) =>
          {
            this.waitlistTableData = res.data.data
          })
      } catch (e) {

      }
    },
    formatBookingTime(bookingCreatedTime)
    {
      return moment(bookingCreatedTime).format('lll')
    },
    async fetchBookings(miniLoader)
    {
      this.axios.get(`/v1/routes/${this.trip.route_id}/busstops`).then((r) =>
      {
        this.busStops = r.data.data
      })

      this.axios
        .get(`/v1/trips/${this.trip.id}/passengers`, {
          params: {
            isUpcomingTrip: this.isUpcomingTrip
          }
        })
        .then((res) =>
        {

        })

      if (!miniLoader) {
        this.loading = true
        this.busCaptain = await this.fetchRouteCaptain()
      } else {
        this.loading = true
      }
      const payload = {
        booking_days: [
          format(
            parseISO(this.trip.start_trip || this.trip.trip_date),
            'yyyy-MM-dd'
          )
        ],
        itinerary_id: this.trip.route_itinerary_id,
        driver_id: this.trip.driver_id
      }

      this.axios
        .post(`/v1/routes/${this.trip.route_id}/bookings/null`, payload)
        .then((res) =>
        {
          const bookings = []
          res.data.data.forEach((dayData) =>
          {
            bookings.push(...(dayData.data || []))
          })
          bookings.forEach((booking) =>
          {
            const key = `${booking.route_itinerary_id}_${booking.user_id}`
            if (this.busCaptain.has(key)) {
              booking.user.bus_captain_id = this.busCaptain.get(key).id
            }
            this.addCiCoAttributes(booking)
          })
          const manifest = new Manifest(bookings)
          this.manifestToShow =
            this.manifestType === 'pickup'
              ? manifest.groupSortedPickupManifestByPickupName()
              : manifest.groupSortedDestinationManifestByDestinationName()
          this.tableData = bookings
          this.totalRecords = this.pageSize = this.tableData.length
        })
        .finally(() =>
        {
          this.loading = false
        })
    },
    closeNotificationModal()
    {
      this.$bvModal.hide(this.notificationModalId)
    },
    openNotificationModal()
    {
      this.$bvModal.show(this.notificationModalId)
    },
    proceedWithSelectedPassengers(passengers)
    {
      this.$bvModal.hide(this.addPassengerToTripModalId)
      this.errorProcessing = false
      this.notificationErrorMessage = null
      passengers.forEach((user) =>
      {
        this.axios
          .post(
            `/v1/trips/${this.trip.id}/passengers`,
            {
              user_id: user.id,
              pickup_route_bus_stop_id: user.pickup_route_bus_stop_id,
              destination_route_bus_stop_id: user.destination_route_bus_stop_id
            },
            {
              params: {
                isUpcomingTrip: this.isUpcomingTrip
              }
            }
          )
          .then(() =>
          {
            this.$swal({
              icon: 'success',
              title: 'Passengers added',
              text: 'Passengers successfully added to trip',
              showCloseButton: true
            })
          })
          .catch((err) =>
          {
            this.errorProcessing = true
            this.notificationErrorMessage = extractErrorMessage(
              err,
              'An error occurred while added passengers to trip!'
            )

            this.$swal({
              icon: 'error',
              title: 'Could not add passengers',
              text: this.notificationErrorMessage,
              showCloseButton: true
            })
          })
          .finally(() =>
          {
            this.refreshTable()
            this.loading = false
          })
      })
    },
    addSelectedPassengers(passengers)
    {
      this.dismissPassengerModal()

      this.selectedPassengers = passengers
      passengers.forEach((user) =>
      {
        user.pickup_route_bus_stop_id = this.busStops[0].id
        user.destination_route_bus_stop_id =
          this.busStops[this.busStops.length - 1].id
      })

      this.$bvModal.show(this.addPassengerToTripModalId)
    },
    dismissPassengerModal()
    {
      this.$bvModal.hide(this.addPassengerModalId)
    },
    addPassengerModal()
    {
      this.$bvModal.show(this.addPassengerModalId)
    },
    notifyUsers()
    {
      this.notificationErrorMessage = ''
      if (!this.isValidNotificationForm) {
        this.notificationErrorMessage =
          'Please provide a message title and description'
        return
      }
      this.sendingNotification = true
      this.axios
        .post('/v1/notifications/', {
          user_ids: this.tableData.map((booking) => booking.user_id),
          title: this.notification.title,
          body: this.notification.description,
          sms: this.notification.isSms
        })
        .then(() =>
        {
          this.$swal({
            icon: 'success',
            title: 'Notification sent',
            text: 'Notification has been sent to route users successfully',
            showCloseButton: true
          })
          this.closeNotificationModal()

          this.notification = {
            title: '',
            description: ''
          }
        })
        .catch((e) =>
        {
          this.errorProcessing = true
          this.notificationErrorMessage = extractErrorMessage(
            e,
            'An error occurred!'
          )
        })
        .finally(() => (this.sendingNotification = false))
    },
    downloadReport()
    {
      const csvData = this.tableData.map((booking) =>
      {
        return {
          amount: booking.unit_cost,
          pickup: booking?.pickup?.location,
          destination: booking?.destination?.location,
          email: booking?.user?.email,
          name: booking?.user?.fname + ' ' + booking?.user?.lname,
          phone: booking?.user?.phone,
          start_date: moment(booking.start_date).format('YYYY-MM-DD'),
          end_date: moment(booking.end_date).format('YYYY-MM-DD')
        }
      })

      const csvParams = {
        filename: `Bookings for ${this.trip.tripDescription}`,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `Bookings for ${this.trip.tripDescription}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Amount',
          'Pickup',
          'Destination',
          'Email',
          'Name',
          'Phone',
          'Start Date',
          'End Date'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)

      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    addCiCoAttributes(booking)
    {
      switch (booking.check_in_status) {
        case 'pending':
          booking.check_in_meta = {
            checkInText: 'Pending',
            checkInClass: 'sh-badge--yellow',
            dropOffText: 'No Status',
            dropOffClass: 'sh-badge--neutral'
          }
          break
        case 'picked-up':
          booking.check_in_meta = {
            checkInText: 'Checked In',
            checkInClass: 'sh-badge--green',
            dropOffText: 'Pending',
            dropOffClass: 'sh-badge--yellow'
          }
          break
        case 'dropped-off':
          booking.check_in_meta = {
            checkInText: 'Checked In',
            checkInClass: 'sh-badge--green',
            dropOffText: 'Dropped Off',
            dropOffClass: 'sh-badge--green'
          }
          break
        case 'no-show':
          booking.check_in_meta = {
            checkInText: 'No show',
            checkInClass: 'sh-badge--red',
            dropOffText: 'No show',
            dropOffClass: 'sh-badge--red'
          }
          break
        default:
          booking.check_in_meta = {
            checkInText: 'No status',
            checkInClass: 'sh-badge--neutral',
            dropOffText: 'No status',
            dropOffClass: 'sh-badge--neutral'
          }
          break
      }
    },
    determineManifestToShow()
    {
      const manifest = new Manifest(this.tableData)
      this.manifestToShow =
        this.manifestType === 'pickup'
          ? manifest.groupSortedPickupManifestByPickupName()
          : manifest.groupSortedDestinationManifestByDestinationName()
    }
  },
  watch: {
    formStep(value)
    {
      if (value === 1) {
        this.selectedUsers.map((user) =>
        {
          user.new_pickup = null
          user.new_dropoff = null
          this.TransferBusStops.filter((i) => i.is_pickup === 1).map((i) =>
          {
            if (i.h3_m50_area_hash === user.pickupRouteBusStop.h3_m50_area_hash) {
              if (user.new_pickup !== null) {
                if (user.new_pickup.position < i.position) {
                  user.new_pickup = i
                }
              } else {
                user.new_pickup = i
              }
            }

            if (user.new_pickup === null) {
              if (i.h3_km5_area_hash === user.pickupRouteBusStop.h3_km5_area_hash) {
                user.new_pickup = i
              }
            }
          })

          this.TransferBusStops.filter((i) => i.is_dropoff === 1).map((i) =>
          {
            if (i.h3_m50_area_hash === user.destinationRouteBusStop.h3_m50_area_hash) {
              if (user.new_dropoff !== null) {
                if (user.new_dropoff.position < i.position) {
                  user.new_dropoff = i
                }
              } else {
                user.new_dropoff = i
              }
            }

            if (user.new_dropoff === null) {
              if (i.h3_km5_area_hash === user.destinationRouteBusStop.h3_km5_area_hash) {
                user.new_dropoff = i
              }
            }
          })
        })


      }
    },
    'form.route'(value, oldValue)
    {
      if (value && value !== oldValue) {
        this.form.itinerary = null
        this.fetchRouteItineraries()
        this.axios.get(`/v1/routes/${this.form.route}/busstops`).then((r) =>
        {
          this.TransferBusStops = r.data.data
        })
      }
    },
    'form.itinerary'(value, oldValue)
    {
      if (value && value !== oldValue) {
        this.form.vehicle = null
        this.fetchItineraryDrivers()
      }
    },
    manifestType()
    {
      this.determineManifestToShow()
    }
  }
}

</script>

<style lang="scss" scoped>
@use 'src/assets/scss/partials/badges';
@use 'src/assets/scss/partials/typography';

$gray-2: #25292D;

::v-deep {
  .b-table {
    font-size: 0.875rem;

    & thead {
      tr>th {
        background: transparent;
      }
    }

    & tbody {
      tr>td {
        vertical-align: middle;
      }
    }

    & .cell-center {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.b-pagination {
  margin-bottom: 0;
}

.table-responsive {
  margin-bottom: 0;
}

.notification-text-area {
  overflow: hidden;
  overflow-wrap: break-word;
  height: 69px;
}

.captain-text {
  //color: #4848ED;
  font-size: 0.75rem;
  font-weight: 500;
}

.flex-flex-row {
  display: flex;
  flex-direction: row;
}

.loading-text {
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 14px;
}

.spinner {
  width: 1rem;
  margin-right: 5px;
  height: 1rem;
  border-width: 0.15rem;
}

::v-deep .multiselect {
  & .multiselect__option--highlight {
    background: $gray-2;

    &:after {
      background: $gray-2;
    }
  }
}

::v-deep .v-select {

  &.vs--single {
    & .vs__selected {
      max-width: 80%;
    }
  }

  & .vs__search::placeholder {
    color: #D3DCE6;
    font-weight: 300;
    font-size: 0.875rem;
  }

  & .vs__dropdown-option {
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    white-space: normal;

    &:hover {
      background: $gray-2;
    }

    &--highlight,
    &--selected {
      background: $gray-2;
    }
  }
}
</style>
