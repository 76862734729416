import { render, staticRenderFns } from "./TripPassengers.vue?vue&type=template&id=21371af6&scoped=true&"
import script from "./TripPassengers.vue?vue&type=script&setup=true&lang=js&"
export * from "./TripPassengers.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TripPassengers.vue?vue&type=style&index=0&id=21371af6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21371af6",
  null
  
)

export default component.exports